<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <eb-filter-user-list v-model="query.userId">
        </eb-filter-user-list>
      </el-col>

      <el-col :span="4">
        <el-input
          v-model="query.orderNo"
          placeholder="订单编号"
          :maxLength="50"
          clearable
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          v-model="query.orderNo"
          placeholder="订单编号"
          :maxLength="50"
          clearable
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-input
          v-model="query.goodsName"
          placeholder="套餐名称"
          :maxLength="50"
          clearable
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="query.orderStatus"
          placeholder="订单状态"
          :maxLength="50"
          clearable
        >
          <el-option
            v-for="(item, index) in this.const.ORDER_STATUS"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="6">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          @change="changeDate"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
        <el-button type="primary" @click="exportData" v-auth="this.per.EXPORT_PRODUCT_RECORD">导出excel</el-button>
      </el-col>
    </el-row>

    <OrderProductRecord ref="OrderProductRecord" :listQuery="query" :pageable="true"></OrderProductRecord>
    
  </div>
</template>

<script>
import { fetchOrderList, exportOrderList } from "@/api/order";
import moment from "moment";
import OrderProductRecord from '@/components/view-components/order-product-record'

const orderType = [
  { name: "月卡", value: "月卡" },
  { name: "半月卡", value: "半月卡" },
  { name: "季卡", value: "季卡" },
  { name: "年卡", value: "年卡" },
  { name: "押金", value: "押金" },
];

export default {
  components: {OrderProductRecord},
  data() {
    let startTime = moment().startOf('day').subtract(1, "month").format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");

    return {
      dateRange: [startTime, endTime],
      dataSource: [],
      orderType: orderType,

      query: {
        startTime: startTime,
        endTime: endTime,
      },
      modalData: {},
      total: 0,
    };
  },
  methods: {
    exportData() {
      let queryData = this.util.deepClone(this.query);
      exportOrderList(queryData).then((res) => {
        this.util.apiDownLoadFile(res.data,"application/vnd.ms-excel",`${queryData.startTime}-${queryData.endTime}订单数据.xlsx`)
      });
    },

    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0]).startOf('day').format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1]).endOf('day').format("YYYY-MM-DD HH:mm:ss");

        this.dateRange = [this.query.startTime, this.query.endTime]
      } else {
         this.query.startTime = '';
        this.query.endTime = '';
      }
    },
    // 修改查询条件
    changeArea(e) {
      if (!this.validatenull(e)) {
        this.query.provinceId = e[0];
        this.query.cityId = e[1];
        this.query.areaid = e[2];
      } else {
        this.query.provinceId = "";
        this.query.cityId = "";
        this.query.areaid = "";
      }
      this.getList(1);
    },

    getList(current) {
      this.$refs.OrderProductRecord.getList(current);
    },
  },

  mounted() {
    this.getList();
  },
};
</script>
